/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Logo from '../../images/logo.png';
import {
  Flex,
  Text,
  HStack,
  useMediaQuery,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  useDisclosure,
  Stack,
  Box,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { getAuthToken } from '../../services/util/localStorage';
import { decodeToken } from '../../services/auth/AuthService';

const NavBar = ({ willAnimate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrolling, setScrolling] = useState(false);
  const [userType, setUserType] = useState(null);

  const token = getAuthToken();

  const [isLargerThan728] = useMediaQuery('(min-width: 728px)');
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan1350] = useMediaQuery('(min-width: 1350px)');

  useEffect(() => {
    const checkDecoded = async () => {
      let decoded = await decodeToken(token);
      if (decoded) {
        setUserType(decoded.role);
      }
    };

    if (token) {
      checkDecoded();
    }
  }, [token]);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setScrolling(false); // Remove background color
      } else {
        setScrolling(true); // Add background color
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let backgroundStyle = {};
  if (willAnimate) {
    backgroundStyle = {
      backgroundColor: scrolling ? '#11a00d' : 'transparent', // Set background color conditionally
      transition: 'background-color 0.3s ease-in-out', // Add smooth transition
    };
  } else {
    backgroundStyle = {
      backgroundColor: '#11a00d',
    };
  }

  return (
    <>
      <Flex
        h={'80px'}
        position={'fixed'}
        w={'100%'}
        style={backgroundStyle}
        zIndex={10}
        alignItems={'center'}
        px={5}
        shadow={'md'}
        justify={'center'}
      >
        <Flex
          direction="row"
          justifyContent="center"
          alignItems={'center'}
          maxW={isLargerThan1024 ? '1580px' : '100%'}
          w={'100%'}
        >
          <Box
            w={isLargerThan1350 ? '750px' : isLargerThan1024 ? '450px' : '100%'}
          >
            <Link href="/" style={{ cursor: 'pointer' }}>
              <Image width={55} height={25} src={Logo} alt="logo" />
            </Link>
          </Box>
          {isLargerThan1024 ? (
            <HStack spacing={8}>
              <Link href="/chauffeur">
                <Text m={0} color={'white'}>
                  Chauffeur
                </Text>
              </Link>
              <Link href="/services">
                <Text m={0} color={'white'}>
                  Services
                </Text>
              </Link>
              <Link href="/contact">
                <Text m={0} color={'white'}>
                  Contact
                </Text>
              </Link>
              <Link href="/app">
                <Text m={0} color={'white'}>
                  Download
                </Text>
              </Link>
              <Link href="/blog">
                <Text m={0} color={'white'}>
                  Blog
                </Text>
              </Link>
              {isLargerThan728 && (
                <Link
                  href={
                    userType && userType !== 'guest'
                      ? '/app/dashboard'
                      : '/app/auth/login'
                  }
                >
                  <Text m={0} color={'white'}>
                    {userType && userType !== 'guest' ? 'My Account' : 'Login'}
                  </Text>
                </Link>
              )}
            </HStack>
          ) : (
            <Button
              name="menu"
              background={'transparent'}
              _hover={{ background: 'transparent' }}
              onClick={onOpen}
              fontWeight={'medium'}
            >
              <GiHamburgerMenu color="white" size={25} />
            </Button>
          )}
        </Flex>
      </Flex>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="full"
        motionPreset="slideInBottom"
      >
        <ModalContent backgroundColor="black">
          <ModalCloseButton color={'white'} mt={4} mr={4} />
          <ModalBody>
            <Flex
              alignContent={'center'}
              justify={'center'}
              textAlign={'center'}
              alignSelf={'center'}
              pt={20}
            >
              <Stack spacing={8} justify={'center'}>
                <Link href="/">
                  <Text m={0} color={'white'}>
                    Home
                  </Text>
                </Link>
                <Link href="/chauffeur">
                  <Text m={0} color={'white'}>
                    Chauffeur
                  </Text>
                </Link>
                <Link href="/services">
                  <Text m={0} color={'white'}>
                    Services
                  </Text>
                </Link>
                <Link href="/faq">
                  <Text m={0} color={'white'}>
                    FAQ
                  </Text>
                </Link>
                <Link href="/contact">
                  <Text m={0} color={'white'}>
                    Contact
                  </Text>
                </Link>
                <Link href="/app">
                  <Text m={0} color={'white'}>
                    Download
                  </Text>
                </Link>
                <Link href="/blog">
                  <Text m={0} color={'white'}>
                    Blog
                  </Text>
                </Link>
                {isLargerThan728 && (
                  <Link href="/app/dashboard">
                    <Text m={0} color={'white'}>
                      {userType && userType !== 'guest'
                        ? 'My Account'
                        : 'Login'}
                    </Text>
                  </Link>
                )}
              </Stack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NavBar;
